var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":"","depressed":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('VToolbarTitle',[_vm._v(" Команды "+_vm._s(_vm.company.name)+" ")]),_c('VSpacer'),_c('VBtn',{attrs:{"color":"primary","to":{ name : _vm.Names.R_ORGSTRUCTURE_COMPANY_TEAM_ADD }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Создать команду ")],1)],1),_c('VDivider'),_c('VCardText',[_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block"},[_vm._v(_vm._s(_vm.company))])])],1)],1),_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить команду? ")]),_c('VCardText',[_vm._v(" Команда "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.team.name))]),_vm._v(" будет удалена! "),_c('br'),_vm._v(" Действительно хотите удалить команду? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1),_c('VDataTable',{attrs:{"items":_vm.teams,"headers":_vm.teamsHeaders,"loading":_vm.loading,"items-per-page":_vm.pagination.limit,"page":_vm.pagination.page,"server-items-length":_vm.pagination.total,"footer-props":{
              itemsPerPageOptions : _vm.pagination.itemsPerPageOptions,
              showFirstLastPage : true
            }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var ref_item = ref.item;
            var id = ref_item.id;
            var name = ref_item.name;
return [_c('RouterLink',{staticClass:"d-block",attrs:{"to":{
                  name : _vm.Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                  params : {
                    companyId : _vm.cid,
                    teamId : id
                  }
                }}},[_vm._v(" "+_vm._s(name)+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"orange","to":{
                      name : _vm.Names.R_ORGSTRUCTURE_COMPANY_TEAM_EDIT,
                      params : {
                        companyId : _vm.cid,
                        teamId : item.id
                      }
                    }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteTeamFromCompany(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }